import { default as aboutoz1oeiH6z4Meta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/about.vue?macro=true";
import { default as logink4y3kqkpv4Meta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/auth/login.vue?macro=true";
import { default as faq3StyHg5qzJMeta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/faq.vue?macro=true";
import { default as indexnSY5DfZcarMeta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/index.vue?macro=true";
import { default as _1_46startRf2hxeqGzSMeta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/form/1.start.vue?macro=true";
import { default as _2_46sessionzWjq0m4ofqMeta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/form/2.session.vue?macro=true";
import { default as _3_46finishsxAZclCZovMeta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/form/3.finish.vue?macro=true";
import { default as forma68e2dYdUmMeta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/form.vue?macro=true";
import { default as homeYe23u7SwrMMeta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/home.vue?macro=true";
import { default as onboardinge18mTKx3LoMeta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/onboarding.vue?macro=true";
import { default as prepareS1ZMIqnpC7Meta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/prepare.vue?macro=true";
import { default as _1_46create_45teamsk3hDPokdMaMeta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/quiz/1.create-teams.vue?macro=true";
import { default as _2_46teamsdLRNvadEALMeta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/quiz/2.teams.vue?macro=true";
import { default as _3_46sessionBYAeoqIBGSMeta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/quiz/3.session.vue?macro=true";
import { default as _4_46resultsaIVz8WnxczMeta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/quiz/4.results.vue?macro=true";
import { default as selectVg2fXrWb2QMeta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/select.vue?macro=true";
import { default as finishIiLJJrBHtaMeta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/scan/finish.vue?macro=true";
import { default as homezT6VBHpc99Meta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/scan/home.vue?macro=true";
import { default as onboardingQnzuDsWU4pMeta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/scan/onboarding.vue?macro=true";
import { default as prepare3GIbM390PdMeta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/scan/prepare.vue?macro=true";
import { default as resumelEB1qtwH4RMeta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/scan/resume.vue?macro=true";
import { default as scanFhPzwAbVlBMeta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/scan/scan.vue?macro=true";
import { default as selectZljRxUI4XBMeta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/scan/select.vue?macro=true";
import { default as settingsKgRXtBtGlwMeta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/settings.vue?macro=true";
import { default as systemZ8Y7IPHUdiMeta } from "/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/system.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: aboutoz1oeiH6z4Meta || {},
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: logink4y3kqkpv4Meta || {},
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/",
    meta: indexnSY5DfZcarMeta || {},
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "quiz-form",
    path: "/quiz/form",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/form.vue").then(m => m.default || m),
    children: [
  {
    name: "quiz-form-1.start",
    path: "1.start",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/form/1.start.vue").then(m => m.default || m)
  },
  {
    name: "quiz-form-2.session",
    path: "2.session",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/form/2.session.vue").then(m => m.default || m)
  },
  {
    name: "quiz-form-3.finish",
    path: "3.finish",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/form/3.finish.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "quiz-home",
    path: "/quiz/home",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/home.vue").then(m => m.default || m)
  },
  {
    name: "quiz-onboarding",
    path: "/quiz/onboarding",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "quiz-prepare",
    path: "/quiz/prepare",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/prepare.vue").then(m => m.default || m)
  },
  {
    name: "quiz-quiz-1.create-teams",
    path: "/quiz/quiz/1.create-teams",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/quiz/1.create-teams.vue").then(m => m.default || m)
  },
  {
    name: "quiz-quiz-2.teams",
    path: "/quiz/quiz/2.teams",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/quiz/2.teams.vue").then(m => m.default || m)
  },
  {
    name: "quiz-quiz-3.session",
    path: "/quiz/quiz/3.session",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/quiz/3.session.vue").then(m => m.default || m)
  },
  {
    name: "quiz-quiz-4.results",
    path: "/quiz/quiz/4.results",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/quiz/4.results.vue").then(m => m.default || m)
  },
  {
    name: "quiz-select",
    path: "/quiz/select",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/quiz/select.vue").then(m => m.default || m)
  },
  {
    name: "scan-finish",
    path: "/scan/finish",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/scan/finish.vue").then(m => m.default || m)
  },
  {
    name: "scan.home",
    path: "/scan/home",
    meta: homezT6VBHpc99Meta || {},
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/scan/home.vue").then(m => m.default || m)
  },
  {
    name: "scan-onboarding",
    path: "/scan/onboarding",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/scan/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "scan.prepare",
    path: "/scan/prepare",
    meta: prepare3GIbM390PdMeta || {},
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/scan/prepare.vue").then(m => m.default || m)
  },
  {
    name: "scan.resume",
    path: "/scan/resume",
    meta: resumelEB1qtwH4RMeta || {},
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/scan/resume.vue").then(m => m.default || m)
  },
  {
    name: "scan-scan",
    path: "/scan/scan",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/scan/scan.vue").then(m => m.default || m)
  },
  {
    name: "scan-select",
    path: "/scan/select",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/scan/select.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsKgRXtBtGlwMeta || {},
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "system",
    path: "/system",
    meta: systemZ8Y7IPHUdiMeta || {},
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20241001095732/pages/system.vue").then(m => m.default || m)
  }
]